import { Show } from 'solid-js';
import IconPhotoNumber from '~/assets/icons/photoNumber.svg';
import { preview, previewMultiple } from '~/components/common/ImagePreview';
import { IconDefaultUnit } from '~/components/ui';

interface PhotoWallProps {
  data: {
    url: string;
    title: string;
  }[];
}

export const PhotoWall = (props: PhotoWallProps) => {
  const previewImages = (index: number) => {
    if (props.data.length === 1) {
      preview(props.data[0].url);
    } else {
      previewMultiple(
        props.data.map((item) => item.url),
        index
      );
    }
  };

  return (
    <div class="relative flex w-full justify-between">
      <Show
        when={props.data.length !== 0}
        fallback={
          <div class="flex h-[400px] w-full items-center justify-center rounded-lg bg-gradient-color-start">
            <IconDefaultUnit color="var(--gradient-color-end)" class="h-modalSm" />
          </div>
        }>
        <>
          <div class={props.data.length === 1 ? 'w-full' : 'w-[979px]'}>
            <img
              id="preview-image-0"
              onClick={() => previewImages(0)}
              class={`cursor-pointer object-cover ${
                props.data.length === 1 ? 'h-[583px] w-full rounded-lg' : 'h-[583px] w-[979px] rounded-l-lg'
              }`}
              src={props.data[0]?.url}
              alt={props.data[0]?.title}
            />
          </div>
          <Show when={props.data.length > 1}>
            <div class="flex flex-col justify-between">
              <div>
                <img
                  id="preview-image-1"
                  onClick={() => previewImages(1)}
                  class={`w-[332px] cursor-pointer object-cover ${
                    props.data.length === 2 ? 'h-[583px] rounded-r-lg' : 'h-[287px] rounded-tr-lg'
                  }`}
                  src={props.data[1]?.url}
                  alt={props.data[1]?.title}
                />
              </div>
              <Show when={props.data.length > 2}>
                <div>
                  <img
                    id="preview-image-2"
                    onClick={() => previewImages(2)}
                    class="h-[287px] w-[332px] cursor-pointer rounded-br-lg object-cover"
                    src={props.data[2]?.url}
                    alt={props.data[2]?.title}
                  />
                </div>
              </Show>
            </div>
          </Show>

          <div
            id="preview-image"
            onClick={() => previewImages(0)}
            class="absolute bottom-2 right-2 flex cursor-pointer items-center gap-1 rounded-lg bg-black/70 px-3 py-1 text-white">
            <img src={IconPhotoNumber} alt="photo number" />
            <span>{props.data?.length}</span>
          </div>
        </>
      </Show>
    </div>
  );
};
